import React, { useEffect, useState } from "react";
import Typist from 'react-typist';
import { Animated } from 'react-animated-css';
import { Helmet } from "react-helmet";
import PubSub from "pubsub-js";
import queryString from "query-string";

import Layout from "../components/Layout.jsx";
import Map from "../components/Map.jsx";
import Contact from "../components/Contact.jsx";

import api from "../apis/public";

const Unsubscribe = () => {
  const [ menuOpen, setMenuOpen ] = useState(false);
  const [ message, setMessage ] = useState(null);
  const [ uuid, setUuid ] = useState(null);

  useEffect(() => {
    let uuidParam = queryString.parse(window.location.search).id;
    setUuid(uuidParam);

    if (uuidParam) {
      unsubscribeForNotifications(uuidParam);
    } else {
      window.location.href = 'https://covidvaxmap.com';
    }
  }, []);

  const unsubscribeForNotifications = async (uuidParam) => {
    let id = uuidParam || uuid;

    PubSub.publish('loading', true);
    let timeout = setTimeout(() => { PubSub.publish('loading', false); }, 15000);

    try {
      await api.unsubscribeForNotifications(id, null);
      
      PubSub.publish('alert', { type: 'success', title: 'Success', body: 'Successfully unsubscribed from real-time alerts' });
      setMessage({
        title: 'Unsubscribed',
        subTitle: 'Thank you!',
        body: 'We appreciate your support in helping achieve a healthier public.'
      });
    } catch(err) {
      console.log(err);
      PubSub.publish('alert', { type: 'danger', title: 'Error', body: err });
      setMessage({
        title: 'Oops!',
        subTitle: 'Unable to unsubscribe from notifications',
        body: 'Please contact support or try again later.'
      });
    } finally {
      PubSub.publish('loading', false);
      clearTimeout(timeout);
    }
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout>
        {message && (
          <section id="home">
            <div className="pt-0 pb-4 bg-gray-50 sm:pt-12 md:pt-8">
              <div className="mx-auto px-4 sm:px-6">
                <div className="text-center">
                  <h2 className="text-3xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-4xl sm:leading-none md:text-5xl">
                    {message.title}
                    <br />
                    <span className="text-2xl sm:text-3xl md:text-4xl text-indigo-600">{message.subTitle}</span>
                  </h2>
                  <p className="mt-4 mb-8 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                    {message.body}
                  </p>
                  <svg className="w-32 h-32 text-red-500 m-auto mb-12" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.31802 6.31802C2.56066 8.07538 2.56066 10.9246 4.31802 12.682L12.0001 20.364L19.682 12.682C21.4393 10.9246 21.4393 8.07538 19.682 6.31802C17.9246 4.56066 15.0754 4.56066 13.318 6.31802L12.0001 7.63609L10.682 6.31802C8.92462 4.56066 6.07538 4.56066 4.31802 6.31802Z" stroke="currentColor" fill="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
                  <a href="https://covidvaxmap.com" class="mb-12 inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                    Return to CovidVaxMap
                  </a>

                </div>
              </div>
            </div>
          </section>
        )}
      </Layout>
    </>
  );
}

export default Unsubscribe;